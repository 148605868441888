import React, { useEffect, useState } from 'react';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import { makeStyles } from '@material-ui/core/styles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import { useTranslation, Link } from 'gatsby-plugin-react-i18next';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import Button from '@material-ui/core/Button';

import Template from '../Template';
import Metadata from '../Metadata';
import CustomAppBar from '../CustomAppBar';
import { EMPLOYERS } from '../../constants';
import LightBox from './LightBox';

export const query = graphql`
  query ($project: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allFile(
      filter: { relativeDirectory: { eq: $project } }
      sort: { fields: base }
    ) {
      edges {
        node {
          id
          base
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
  }
`;

const calculateList = (screenWidth, screenHeight, sub) => {
  if (sub === EMPLOYERS) {
    if (isWidthUp('lg', screenWidth)) {
      return { cols: 5, rowHeight: 200 };
    }
    if (isWidthUp('sm', screenWidth)) {
      return { cols: 3, rowHeight: 150 };
    }
    return { cols: 2, rowHeight: 150 };
  }
  const height = screenHeight / 3 - 3 * 2;
  if (isWidthUp('md', screenWidth)) {
    return { cols: 3, rowHeight: height };
  }
  if (isWidthUp('sm', screenWidth)) {
    return { cols: 2, rowHeight: 200 };
  }
  return { cols: 1, rowHeight: 300 };
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0 !important',
    justifyContent: 'center',
  },

  appBar: {
    [theme.breakpoints.down('sm')]: {
      '& .MuiToolbar-root': {
        justifyContent: 'center',
      },
      top: 78.7,
    },
    [theme.breakpoints.down('xs')]: {
      top: 56.75,
    },
  },

  gatsbyImage: {
    height: '100%',
    width: '100%',
    transition: 'all .5s ease-in-out',
    '&:not($employer):hover': {
      transform: 'scale(1.2)',
      transition: 'transform .8s ease-in-out',
    },
    cursor: 'pointer',
  },

  employer: {
    cursor: 'unset',
  },
}));

const ProjectsGallery = ({ width, data, pageContext }) => {
  const { t } = useTranslation();
  const { project } = pageContext;
  const sub = project.substr(project.indexOf('/') + 1);
  const title = `${t('Menu.Projects.text')} - ${t(`Menu.Projects.${sub}`)}`;
  const images = data.allFile.edges;
  const height = window.innerHeight;
  const classes = useStyles();
  const objectFit = sub === EMPLOYERS ? 'contain' : 'cover';
  const gatsbyImageClass =
    sub === EMPLOYERS
      ? `${classes.gatsbyImage} ${classes.employer}`
      : classes.gatsbyImage;

  const [cols, setCols] = useState(0);
  const [rowHeight, setRowHeight] = useState(0);
  const [selectedImage, setSelectedImage] = useState();
  const [showLightbox, setShowLightbox] = useState(false);

  useEffect(() => {
    let calculate = calculateList(width, height, sub);
    setCols(calculate.cols);
    setRowHeight(calculate.rowHeight);
  }, [width, height, sub]);

  const handleOpen = (i) => {
    if (sub === EMPLOYERS) {
      return;
    }
    setShowLightbox(true);
    setSelectedImage(i);
  };

  const handlePrevRequest = (i, length) => (e) => {
    setSelectedImage((i - 1 + length) % length);
  };
  const handleNextRequest = (i, length) => (e) => {
    setSelectedImage((i + 1) % length);
  };

  const handleClose = () => {
    setShowLightbox(false);
    setSelectedImage();
  };

  return (
    <Template>
      <Metadata pageTitle={title} />

      <CustomAppBar customClass={classes.appBar}>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={
            <ArrowBackIosRoundedIcon
              style={{ transform: t('assets.css.scaleX') }}
            />
          }
          component={Link}
          to="/projects"
        >
          {t('Menu.Projects.text')}
        </Button>
      </CustomAppBar>

      <ImageList
        rowHeight={rowHeight}
        cols={cols}
        gap={sub === EMPLOYERS ? 20 : 6}
        classes={{ root: classes.root }}
      >
        {images.map(({ node }, i) => (
          <ImageListItem key={node.id} onClick={() => handleOpen(i)}>
            <GatsbyImage
              image={node.childImageSharp.gatsbyImageData}
              alt={node.base}
              placeholder="blurred"
              className={gatsbyImageClass}
              objectFit={objectFit}
            />
          </ImageListItem>
        ))}
      </ImageList>

      {showLightbox && selectedImage !== null && (
        <LightBox
          images={images}
          handleClose={handleClose}
          handleNextRequest={handleNextRequest}
          handlePrevRequest={handlePrevRequest}
          selectedImage={selectedImage}
        />
      )}
    </Template>
  );
};

export default withWidth()(ProjectsGallery);
