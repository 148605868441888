import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

const NonStretchedImage = ({ image }) => {
  return (
    <GatsbyImage
      image={image.childImageSharp.gatsbyImageData}
      alt={image.base}
      style={{
        maxHeight: '90vh',
        maxWidth: '80%',
        margin: '50px auto',
      }}
      objectFit="contain"
    />
  );
};
export default NonStretchedImage;
