import React from 'react';

//https://github.com/treyhuffine/lightbox-react/blob/master/src/lightbox-react.js
//https://reactjsexample.com/lightbox-for-components-or-images-built-for-react/
import LightboxReact from 'lightbox-react';
import 'lightbox-react/style.css';
import { makeStyles } from '@material-ui/core/styles';

import NonStretchedImage from './nonStretchedImage';

const useStyles = makeStyles({
  portalClassName: {
    '& .ril-image-current': {
      display: 'flex',
      pointerEvents: 'none',
    },
  },
});

const customStyle = {
  overlay: {
    zIndex: 2000,
  },
};

const LightBox = ({
  images,
  selectedImage,
  handleClose,
  handlePrevRequest,
  handleNextRequest,
}) => {
  const classes = useStyles();
  const array = [];

  images.forEach((image) =>
    array.push(<NonStretchedImage image={image.node} />)
  );

  return (
    <LightboxReact
      enableZoom={false}
      clickOutsideToClose={true}
      mainSrc={array[selectedImage]}
      nextSrc={array[(selectedImage + 1) % array.length]}
      prevSrc={array[(selectedImage + array.length - 1) % images.length]}
      onCloseRequest={handleClose}
      onMovePrevRequest={handlePrevRequest(selectedImage, array.length)}
      onMoveNextRequest={handleNextRequest(selectedImage, array.length)}
      reactModalStyle={customStyle}
      wrapperClassName={classes.portalClassName}
    />
  );
};

export default LightBox;
